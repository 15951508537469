import { Injectable } from '@angular/core';
import { Subject, lastValueFrom } from 'rxjs';
import {
  EventCalendarResponse,
  EventModalMode,
  EventModalObjects,
  EventModalResult,
  EventResponse,
  ExtendSession,
  UpcomingEvents,
} from 'src/app/shared/models/Event.model';
import { MiniLoaderService } from 'src/app/shared/modules/mini-loader/mini-loader.service';
import { EventService } from 'src/app/shared/services/event.service';
import {
  getDurationByHours,
  getUTCDateFromOffset,
} from 'src/app/shared/time.functions';
import { SimulatorsService } from '../simulators/simulators.service';
import { SimulatorResponse } from 'src/app/shared/models/simulators.model';
import { WebSocketService } from 'src/app/shared/services/WebSocket.service';
import { SignalType } from 'src/app/shared/models/Enums';
import { AlertType } from 'src/app/shared/models/alert';
import {
  Transaction,
  TransactionType,
} from 'src/app/shared/models/transaction';
import { environment } from 'src/environments/environment';
import { NotificationService } from 'src/app/shared/modules/notification/notification.service';
import { EventWorkerService } from 'src/app/shared/services/worker/event-worker/event-worker.service';
import { HelperService } from 'src/app/shared/services/Helper.service';
import { MatDialog } from '@angular/material/dialog';
import { EventModalComponent } from 'src/app/components/event-modal/event-modal.component';
import { RestApiService } from 'src/app/shared/services/RestApiServices.service';

const urlBase = '/Event';

@Injectable({
  providedIn: 'root',
})
export class EventCommonService {
  // private _subscriptions = new Subscription();
  //public transaction$ = new BehaviorSubject<Transaction>(null as any);

  private transaction = new Subject<Transaction>();
  public transaction$ = this.transaction.asObservable();

  // private _user: UserResponse;
  // public get user(): UserResponse {
  //   return this._user;
  // }
  // public set user(v: UserResponse) {
  //   this._user = v;
  // }

  private _currentEvents: EventCalendarResponse[];
  public get currentEvents(): EventCalendarResponse[] {
    return this._currentEvents;
  }
  public set currentEvents(v: EventCalendarResponse[]) {
    this._currentEvents = v;
  }

  private _currentDateSelected: Date;
  public get currentDateSelected(): Date {
    return this._currentDateSelected;
  }
  public set currentDateSelected(v: Date) {
    this._currentDateSelected = v;
  }

  constructor(
    private loaderService: MiniLoaderService,
    private eventService: EventService,
    private simulatorService: SimulatorsService,
    public socketService: WebSocketService,
    public notificatrionService: NotificationService,
    private eventWorkerService: EventWorkerService,
    private helper: HelperService,
    public dialog: MatDialog,
    private restApiService: RestApiService
  ) {
    this._currentDateSelected = this.eventService.today;
    // this.user = new UserResponse({});
    this._currentEvents = new Array<EventCalendarResponse>();

    this.socketService.newConnection$.subscribe((connection) => {
      let status = 'on unknown status';

      if (connection.signaType == SignalType.Connected) status = 'online';
      else if (connection.signaType == SignalType.Disconnected)
        status = 'offline';
    });

    this.socketService.Transaction$.subscribe((p) => {
      if (
        p.transactionType == TransactionType.EventCancel ||
        p.transactionType == TransactionType.EventDelete
      ) {
        if (p.tabId != sessionStorage.getItem('BayManager-TabId')) {
          //this.eventService.removeFromUpcoming(p.eventId);
          this.removeFromCurrentList(p.eventId);

          window.localStorage.removeItem(environment.bayManagerUser);

          this.eventWorkerService.terminateEvent(
            new UpcomingEvents({
              id: p.eventId,
              simulatorId: p.simulatorId,
            })
          );
          this.eventWorkerService.resetWorker();
          this.transaction.next(p);
        }
      }

      if (p.transactionType == TransactionType.EventCreate) {
        if (p.tabId != sessionStorage.getItem('BayManager-TabId')) {
          this.eventService
            .getItemById(p.eventId, this.urlBase)
            .subscribe((response) => {
              if (response) {
                window.localStorage.removeItem(environment.bayManagerUser);
                response.endDateTime = getUTCDateFromOffset(
                  response.endDateTime
                );
                response.startDateTime = getUTCDateFromOffset(
                  response.startDateTime
                );

                this.eventService.pushInUpcoming(response);
                //this.pushInCurrentList(response);
                this.eventWorkerService.resetWorker();
                this.transaction.next(p);
              }
            });
        }
      }

      if (p.transactionType == TransactionType.EventUpdate) {
        if (p.tabId != sessionStorage.getItem('BayManager-TabId')) {
          this.eventService
            .getItemById(p.eventId, this.urlBase)
            .subscribe((response) => {
              if (response) {
                window.localStorage.removeItem(environment.bayManagerUser);
                response.endDateTime = getUTCDateFromOffset(
                  response.endDateTime
                );
                response.startDateTime = getUTCDateFromOffset(
                  response.startDateTime
                );

                this.eventService.editFromUpcoming(response);
                //this.editFromCurrentList(response);
                this.eventWorkerService.resetWorker();

                this.transaction.next(p);
              }
            });
        }
      }

      if (p.transactionType == TransactionType.EventExtend) {
        if (p.tabId != sessionStorage.getItem('BayManager-TabId')) {
          console.log('session was extended : ' + p.extendedMinutes);

          let event = new ExtendSession({
            eventId: p.eventId,
            minutes: p.extendedMinutes,
            simid: p.simulatorIdentifier,
            approved: true,
          });

          this.eventService.extendMinuteFromUpcoming(event);
          this.extendMinuteCurrentList(event);
          this.eventWorkerService.resetWorker();

          this.notificatrionService.filterNotification(
            p.eventId,
            p.simulatorId,
            AlertType.extendSession
          );

          this.transaction.next(p);
        }
      }
    });

    // this._subscriptions.add();

    // this._subscriptions.add();
  }

  public get urlBase(): string {
    return urlBase;
  }

  public async loadAllLocationsAndSimulators(): Promise<SimulatorResponse[]> {
    try {
      this.loaderService.loader = true;
      let simulators = new Array<SimulatorResponse>();

      const locations = await lastValueFrom(
        this.simulatorService.getLocationsAndSimulatorsSelection(
          this.restApiService.user?.company?.id
        )
      );

      localStorage.setItem('locationsandsims', JSON.stringify(locations));
      if (locations && locations.length) {
        locations.forEach((location) => {
          location?.simulators?.forEach((simulator) => {
            if (!simulator.isExpired) {
              let response = new SimulatorResponse({
                id: simulator.id,
                name: simulator.name,
              });

              response.isOnline = this.socketService.connections?.find(
                (f) => f.simId === simulator.simulatorIdentifier
              )
                ? true
                : false;

              simulators.push(response);
            }
          });
        });
      }
      
      return simulators;
    } catch (error) {
      console.log(error);
      return new Array<SimulatorResponse>();
    } finally {
      this.loaderService.loader = false;
    }
  }

  public async onEditEvent(event: EventResponse): Promise<EventResponse> {
    try {
      const DBevent = await lastValueFrom(
        this.eventService.getItemById(event.id, this.urlBase)
      );

      let rollBack = false;
      let timeExtended = false;
      let signalType = SignalType.UpdateEvent;
      let shouldSendSignalToLauncher =
        event.endDateTime <= new Date() ? false : true;

      if (DBevent) {
        await this.eventService.setSessionActivate(event.id, DBevent);

        if (
          new Date(DBevent.endDateTime) > event.endDateTime &&
          this.eventService.isSessionActive
        ) {
          rollBack = true;
          signalType = SignalType.RollBack;
        } else if (
          new Date(DBevent.endDateTime) < event.endDateTime &&
          this.eventService.isSessionActive
        ) {
          console.log('Session was extended');
          timeExtended = true;

          let originalEndTime = new Date(DBevent.endDateTime).getTime(),
            newEndTime = new Date(event.endDateTime).getTime();
          let timeDiff = newEndTime - originalEndTime;
          let minutes = Math.floor(timeDiff / 60000);

          if (shouldSendSignalToLauncher)
            this.socketService.sendSignal(
              event.simulatorIdentifier,
              SignalType.UpdateEvent,
              new Date(event.startDateTime).getTime(),
              new Date(newEndTime).getTime(),
              event.description,
              {
                'event-id': event.id.toString(),
                'event-name': event.name,
                'event-type': 'extend',
                'extend-minutes': minutes,
              }
            );
        }
      }

      const result = await this.eventService.updateEventGlobal(event);

      if (!timeExtended && shouldSendSignalToLauncher) {
        this.socketService.sendSignal(
          event.simulatorIdentifier,
          rollBack ? SignalType.RollBack : SignalType.UpdateEvent,
          event.startDateTime.getTime(),
          event.endDateTime.getTime(),
          event.description,
          {
            'event-id': event.id.toString(),
            'event-name': event.name,
            'event-type': rollBack ? 'rollback' : 'update',
          }
        );
      }

      this.eventService.isSessionActive = false;

      if (result) {
        return event;
      }

      return null as any;
    } catch (error) {
      this.helper.Notify(
        'Event ' + event.name + ' was not saved due to some error',
        'ERROR',
        3000
      );
      return null as any;
    } finally {
      this.eventService.isSessionActive = this.loaderService.loader = false;
    }
  }

  public async onOpenEventModal(
    data: EventModalObjects
  ): Promise<EventModalResult> {
    try {
      if (!data.walkIn && !data.id && !data.selectInfo) {
        let selectInfo = {
          start: new Date(this.currentDateSelected), // + "T00:00:00",
          end: new Date(2024, 1, 1), // + "T00:00:00"
        };

        let newDate = new Date();
        selectInfo.start.setHours(newDate.getHours());
        selectInfo.start.setMinutes(newDate.getMinutes());
        selectInfo.start.setSeconds(newDate.getSeconds());

        data.selectInfo = selectInfo;
      }

      const dialogRef = this.dialog.open(EventModalComponent, {
        width: '600px',
        backdropClass: 'background-blur',
        data: new EventModalObjects({ ...data }),
      });

      const result: EventModalResult = await lastValueFrom(
        dialogRef.afterClosed()
      );

      if (result) {
        if (result.mode === EventModalMode.create && result.event) {
          const newEvent = result.event;
          this.loaderService.loader = true;

          const resultCreated = await this.eventService.createEventGlobal(
            newEvent
          );

          this.loaderService.loader = false;
          if (resultCreated.id <= 0) return null as any;

          newEvent.id = resultCreated.id;
          this.eventWorkerService.resetWorker();

          return new EventModalResult({
            event: newEvent,
            mode: result.mode,
            fromLocation: result.fromLocation,
            fromSimulator: result.fromSimulator,
          });
        } else if (result.mode === EventModalMode.changed && result.event) {
          const editEvent = result.event;
          editEvent.location = result.fromLocation;

          const response = await this.onEditEvent(editEvent);
          this.editFromCurrentList(response);

          this.eventWorkerService.resetWorker();

          return new EventModalResult({
            event: response,
            mode: result.mode,
            fromLocation: result.fromLocation,
            fromSimulator: result.fromSimulator,
          });
        } else if (result.mode === EventModalMode.delete && result.event) {
          await this.eventService.removeEventGlobal(result.event, true);
          this.removeFromCurrentList(result.event.id);

          this.eventWorkerService.resetWorker();

          return new EventModalResult({
            event: result.event,
            mode: result.mode,
            fromLocation: result.fromLocation,
            fromSimulator: result.fromSimulator,
          });
        }
      }

      return null as any;
    } catch (error) {
      console.log(error);
      return null as any;
    }
  }

  public pushInCurrentList(event: EventResponse): void {
    this._currentEvents.push(new EventResponse({ ...event }));
    this._currentEvents.sort((a, b) => {
      if (
        getUTCDateFromOffset(a.startDateTime) >
        getUTCDateFromOffset(b.startDateTime)
      ) {
        return 1;
      } else if (
        getUTCDateFromOffset(a.startDateTime) <
        getUTCDateFromOffset(b.startDateTime)
      ) {
        return -1;
      }
      return 0;
    });
  }

  public editFromCurrentList(event: EventResponse): void {
    for (let j = 0; j < this._currentEvents.length; j++) {
      if (this._currentEvents[j].id === event.id) {
        this._currentEvents[j].endDateTime = event.endDateTime;
        this._currentEvents[j].startDateTime = event.startDateTime;
        this._currentEvents[j].locationName = event.locationName;
        this._currentEvents[j].name = event.name;
        this._currentEvents[j].simulatorId = event.simulatorId;
        this._currentEvents[j].simulatorName = event.simulatorName;
      }
    }
  }

  public findFromCurrentList(eventId: number): EventResponse {
    return this.currentEvents?.find((f) => f.id === eventId);
  }

  public extendMinuteCurrentList(event: ExtendSession): void {
    let extendedEvent = this._currentEvents.find((f) => f.id === event.eventId);

    if (extendedEvent) {
      extendedEvent.endDateTime.setMinutes(
        extendedEvent.endDateTime.getMinutes() + event.minutes
      );
    }
  }

  public removeFromCurrentList(eventId: number): void {
    this._currentEvents = this.helper.removeElement(
      this._currentEvents,
      eventId
    );
  }

  public getDuration(first: Date, second: Date): string {
    return getDurationByHours(second, first);
  }
}
