import { Component, OnInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { NotificationService } from './shared/modules/notification/notification.service';
import { AuthService, ValidationStatus } from './shared/services/AuthService';
import { ActivatedRoute, Router } from '@angular/router';
import { WebSocketService } from './shared/services/WebSocket.service';
import { environment } from 'src/environments/environment';
import { EventService } from './shared/services/event.service';
import { lastValueFrom } from 'rxjs';
import { RestApiService } from './shared/services/RestApiServices.service';
import { EventWorkerService } from './shared/services/worker/event-worker/event-worker.service';
import { HelperService } from './shared/services/Helper.service';
import { TranslateService } from '@ngx-translate/core';
import { MiniLoaderService } from './shared/modules/mini-loader/mini-loader.service';
import { CompanyType } from './shared/models/Enums';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  // call this event handler before browser refresh
  // @HostListener('window:beforeunload', ['$event'])
  // unloadHandler(event: Event) {
  //   this.processData();
  // }

  loader: boolean;
  showStartPage: boolean;
  tokenHelper = new JwtHelperService();

  constructor(
    public authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService,
    private restApiService: RestApiService,
    public socketService: WebSocketService,
    private eventWorkerService: EventWorkerService,
    private helperService: HelperService,
    private translate: TranslateService,
    public loaderService: MiniLoaderService
  ) {
    console.log('Version: 1.0.47');

    this.loader = false;

    if (!sessionStorage.getItem('BayManager-TabId')) {
      var tabId = 'TabId:' + Math.floor(1000 + Math.random() * 9000);
      sessionStorage.setItem('BayManager-TabId', tabId);
    }

    this.notificationService.extendMinutes$.subscribe((extended) => {
      if (extended && extended.approved && extended.simId) {
        this.eventService.extendMinuteFromUpcoming(extended);
        this.eventWorkerService.resetWorker();
      }
    });

    this.showStartPage = true;
    this.addListeners();
    this.getQueryParams();
  }

  async ngOnInit(): Promise<void> {
    this.showStartPage = true;

    try {
      this.loaderService.loader = true;

      const tokenValidationResult = await this.authService.isTokenValid();

      if (!tokenValidationResult) {
        await this.authService.unautorized();
        return;
      }

      const isAuth = await this.authService.isAuthenticatedCognitoGroups();

      if (!isAuth) {
        this.helperService.Notify(
          this.translate.instant('userNotBMAdmin'),
          this.translate.instant('error')
        );
        await this.authService.unautorized();
        return;
      }

      const userId = await this.authService.getUserId();

      if (!userId?.length) {
        await this.authService.unautorized();
        return;
      }

      const user = await lastValueFrom(
        this.restApiService.getUserByUserId(userId)
      );

      if (!user?.id || !user?.cognitoId?.length) {
        await this.authService.unautorized();
        return;
      }

      if (user?.company?.companyType !== CompanyType.Commercial) {
        this.helperService.Notify(
          this.translate.instant('failed'),
          this.translate.instant('userNotCommercial'),
          3000
        );
        await this.authService.unautorized();
        return;
      }

      if (
        !user ||
        !user.company?.isBayManager ||
        !user.location?.isBayManager ||
        !user.baymanagerAdmin
      ) {
        if (!user) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('loginError'),
            3000
          );
        } else if (!user.company?.isBayManager) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('companyNotBM'),
            3000
          );
        } else if (!user.location?.isBayManager) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('usersLocNotBM'),
            3000
          );
        } else if (!user.baymanagerAdmin) {
          this.helperService.Notify(
            this.translate.instant('failed'),
            this.translate.instant('userNotBM'),
            3000
          );
        }

        await this.authService.unautorized();
        return;
      }

      this.restApiService.user = user;

      this.restApiService.initialDBObjects();
      await this.socketService.openSocket();
      this.notificationService.requestAndShowPermission();
      await this.initialEvents(user.company.id);

      this.authService.validationResult = ValidationStatus.Valid;
      this.showStartPage = false;

      let urlLength = window.location.pathname.length;
      if (urlLength < 2) {
        this.router.navigate([environment.defaultPage]);
      }

      this.checkSocketEndSession();
    } catch (error) {
      await this.authService.unautorized();
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  addListeners(): void {
    //let self: this;

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        console.log('tab status : ' + document.visibilityState);
        // this.socketService.getOnlineSims()
      } else {
        console.log('tab status : ' + document.visibilityState);
        // this.socketService.getOnlineSims()
      }
    });

    window.addEventListener('pageshow', function (event) {
      console.log('pageshow ', event);
      if (event.persisted) {
        // comes from cache
      }
    });
  }

  checkSocketEndSession(): void {
    this.socketService.EndSession$.subscribe(async (eventId) => {
      try {
        const deleteResult = await lastValueFrom(
          this.eventService.removeItem({ id: eventId }, '')
        );
        location.reload();
        console.log('event deleted from app comp');
      } catch (error) {
        console.error(error);
        window.location.reload();
      }
    });
  }

  getQueryParams(): void {
    this.route.queryParams.subscribe((params) => {
      let email: string = params['email'] ?? '',
        code: string = params['code'] ?? '';

      if (email.indexOf(' ') > -1) {
        email = email.replace(/ /g, '+');
      }

      if (email?.length && code?.length) {
        this.showStartPage = false;
        this.router.navigate(['login'], {
          queryParams: {
            code: code,
            email: email,
          },
        });
      }
    });
  }

  checkAuthentication(): void {
    this.showStartPage = false;
    this.router.navigate(['login']);
  }

  processData(): void {
    // store data into local storage before browser refresh
    window.localStorage.removeItem(environment.bayManagerUser);
  }

  /**
   * Initial upcoming events and worker service
   *
   */
  async initialEvents(companyId: number): Promise<void> {
    try {
      await this.eventService.loadAllUpcomingEvents(companyId);
      this.eventWorkerService.resetWorker();
    } catch (error) {
      console.error(error);
    }
  }
}
