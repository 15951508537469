import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import {
  MatDialogRef,
  MAT_DIALOG_DATA,
  MatDialog,
} from '@angular/material/dialog';
import {
  EventModalMode,
  EventModalObjects,
  EventModalResult,
  EventResponse,
  EventShow,
} from 'src/app/shared/models/Event.model';
import { WebSocketService } from 'src/app/shared/services/WebSocket.service';
import { ClientType } from 'src/app/shared/models/Enums';
import {
  SimulatorResponse,
  SimulatorSelectList,
} from 'src/app/shared/models/simulators.model';
import { RestApiService } from 'src/app/shared/services/RestApiServices.service';
import { HelperService } from 'src/app/shared/services/Helper.service';
import {
  LocationResponse,
  LocationSelection,
  LocationWithSimulatorSelection,
} from 'src/app/shared/models/location.model';
import { CustomersService } from 'src/app/pages/content/customers/customers.service';
import { CustomerSelectList } from 'src/app/shared/models/customer/customer-select-list.model';
import { NgxMatTimepickerComponent } from 'ngx-mat-timepicker';
import { CustomersModalComponent } from '../customers-modal/customers-modal.component';
import { EventService } from 'src/app/shared/services/event.service';
import { SimulatorsService } from 'src/app/pages/content/simulators/simulators.service';
import {
  getDateOnly,
  getHoursFromTimeFormat,
  getMinutesFromTimeFormat,
  getTime,
  getTimeFromMoment,
  getUTCDateFromOffset,
  set24TimeFormat,
} from 'src/app/shared/time.functions';
import { forkJoin, lastValueFrom } from 'rxjs';
import { MiniLoaderService } from 'src/app/shared/modules/mini-loader/mini-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { LocaleService } from 'src/app/shared/services/locale.service';
import { ColorPalette } from 'src/app/shared/models/enums copy';
import { AlertModalType } from '../alert-modal/alert-modal-type.enum';
import { AlertModalOption } from '../alert-modal/alert-modal.model';
import { AlertModalComponent } from '../alert-modal/alert-modal.component';

const urlBase = '/Event';

interface IEventControls {
  name: FormControl;
  description: FormControl;
  simulatorId: FormControl;
  eStartTime: FormControl;
  eEndTime: FormControl;
  eSelectedDate: FormControl;
  customerId: FormControl;
}

@Component({
  selector: 'app-event-modal',
  templateUrl: './event-modal.component.html',
  styleUrls: ['./event-modal.component.scss'],
})
export class EventModalComponent implements OnInit {
  @ViewChild('customerInput') customerInput: ElementRef;
  @ViewChild('endTimePicker') endTimePicker: NgxMatTimepickerComponent;

  // companyId: number;
  event: EventShow & EventResponse;
  eventMode: EventModalMode;

  customers: CustomerSelectList[];
  tmpCustomers: CustomerSelectList[];
  locations: LocationWithSimulatorSelection[];

  simulatorSelectedObjec: {
    simulator: SimulatorSelectList;
    location: LocationSelection;
  };

  eventControls: IEventControls = {
    name: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    description: new FormControl('', [Validators.maxLength(80)]),
    simulatorId: new FormControl(0, [Validators.required, Validators.min(1)]),
    eStartTime: new FormControl('', [Validators.required]),
    eEndTime: new FormControl('', [Validators.required]),
    eSelectedDate: new FormControl(null as any, []),
    customerId: new FormControl(null, []),
  };
  eventForm: FormGroup;

  //---------------old variables----------------
  eventResult: EventModalResult;
  startTime: string;
  endTime: string;
  sessionMessage = '';
  isDeleted: boolean;
  title: string;

  walkIn = false;
  //isSessionActive: boolean = false;

  timeZones: any[] = [
    { value: 'est', viewValue: 'Eastern Standard Time (EST)' },
    { value: 'cst', viewValue: 'Central Standard Time (CST)' },
    { value: 'mst', viewValue: 'Mountain Standard Time (MST)' },
    { value: 'pst', viewValue: 'Pacific Standard Time (PST)' },
  ];

  constructor(
    public socketService: WebSocketService,
    private restApiService: RestApiService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<EventModalComponent>,
    public helper: HelperService,
    private customerService: CustomersService,
    public eventService: EventService,
    public dialog: MatDialog,
    public simulatorService: SimulatorsService,
    public loaderService: MiniLoaderService,
    public translate: TranslateService,
    public locale: LocaleService,
    @Inject(MAT_DIALOG_DATA) public data: EventModalObjects
  ) {
    // this.companyId = 0;
    this.event = new EventResponse({});
    this.walkIn = this.data.walkIn;
    this.customers = this.tmpCustomers = new Array<CustomerSelectList>();
    this.eventResult = new EventModalResult({});
    this.simulatorSelectedObjec = {
      location: new LocationSelection({}),
      simulator: new SimulatorSelectList({}),
    };
    this.createForms();
  }

  initialTimeSelected(): void {
    this.eventControls.eSelectedDate = this.data.selectInfo
      ? new FormControl(new Date(this.data.selectInfo.start), [])
      : new FormControl(new Date(), []);
  }

  initialStartTime(): void {
    let initDate = new Date();
    if (this.data.startDateTime) {
      initDate = this.data.startDateTime;
    }

    this.eventControls.eStartTime.setValue(
      this.helper.getTimeFromMoment(initDate)
    );
  }

  createForms(): void {
    this.initialTimeSelected();

    this.eventForm = this.formBuilder.group(this.eventControls);

    //Get event conflicted for each simulator change
    this.eventControls.simulatorId.valueChanges.subscribe((result) => {
      if (result && result > 0) {
        this.eventService.loadAllConflictedEvents(result);
      }
    });
  }

  checkViewState(): void {
    if (!this.data.walkIn) {
      if (
        this.data.calendarViewState &&
        (this.data.calendarViewState === 'resourceTimeGridDay' ||
          this.data.calendarViewState === 'timeGridWeek')
      ) {
        this.eventControls.eStartTime.setValue(
          getTimeFromMoment(this.data.startDateTime)
        );
        this.eventControls.eEndTime.setValue(
          getTimeFromMoment(this.data.endDateTime)
        );

        if (
          this.data.calendarViewState === 'resourceTimeGridDay' &&
          this.data.simulatorId
        ) {
          this.eventControls.simulatorId.setValue(this.data.simulatorId);
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    try {
      this.title = this.translate.instant('bookEvent');
      this.loaderService.loader = true;

      await this.loadObjects();
      this.setEventModalMode();
      this.checkViewState();

      if (this.data.id && this.data.id > 0) {
        this.title = this.translate.instant('editEvent');

        const response = await lastValueFrom(
          this.eventService.getItemById(this.data.id, urlBase)
        );

        await this.eventService.setSessionActivate(response.id, response);

        if (this.eventService.isSessionActive) {
          this.eventControls.simulatorId.disable();
        }

        this.event = response;
        let startDateUTC = getUTCDateFromOffset(response.startDateTime),
          endDateUTC = getUTCDateFromOffset(response.endDateTime);

        this.eventForm.patchValue({ ...response });
        this.eventControls.eStartTime.setValue(
          getTimeFromMoment(getTime(startDateUTC))
        );
        this.eventControls.eEndTime.setValue(
          getTimeFromMoment(getTime(endDateUTC))
        );
        this.eventControls.eSelectedDate.setValue(startDateUTC);
        this.setCustomerId();
      } else {
        this.eventService.isSessionActive = false;

        this.initialEventModal();
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  async loadObjects(): Promise<void> {
    try {
      const result = await lastValueFrom(
        forkJoin([
          this.customerService.getCustomersSelectList(
            this.restApiService.user?.company?.id
          ),
          this.simulatorService.getLocationsAndSimulatorsSelection(
            this.restApiService.user?.company?.id
          ),
        ])
      );

      this.customers = result[0];
      this.locations = result[1];

      if (this.event && this.eventMode === EventModalMode.changed) {
        if (this.locations) {
          this.locations?.forEach((location) => {
            location.simulators?.forEach((simulator) => {
              if (this.event.simulatorId === simulator.id) {
                this.simulatorSelectedObjec.location = location;
                this.simulatorSelectedObjec.simulator = simulator;
              }
            });
          });
        }
      }

      this.tmpCustomers = this.customers;
    } catch (error) {
      console.error(error);
    }
  }

  initialEventModal(): void {
    this.initialStartTime();
    this.resetSelectedDate();
  }

  /**
   * Set date picker time from time picker
   */
  resetSelectedDate(): void {
    let selectedDate = new Date(this.eventControls.eSelectedDate.value),
      hour = this.helper.getHoursFromTimeFormat(
        this.helper.set24TimeFormat(this.eventControls.eStartTime.value)
      ),
      min = this.helper.getMinutesFromTimeFormat(
        this.helper.set24TimeFormat(this.eventControls.eStartTime.value)
      );

    selectedDate.setMinutes(min);
    selectedDate.setHours(hour);
    this.eventControls.eSelectedDate.setValue(selectedDate);
  }

  resetStartTime(): void {
    this.initialStartTime();
    this.resetEndTime();
  }

  resetEndTime(): void {
    this.eventControls.eEndTime.setValue('');
    this.resetSelectedDate();
  }

  bindEvent(event: EventShow): void {
    if (event) {
      this.event = new EventShow({
        id: event.id,
        name: event.name,
        description: event.description,
        simulatorId: event.simulatorId,
        startTime: event.startTime,
        endTime: event.endTime,
        customerId: event.customerId,
      });
    }
  }

  onTimeChange(event: any) {
    this.eventControls.eEndTime.setValue('');
    this.resetSelectedDate();
  }

  onSaveClick(): void {
    if (!this.eventForm.valid) return;

    this.loaderService.actionLoader = true;

    let st: string = this.eventForm.value.eStartTime,
      et: string = this.eventForm.value.eEndTime;

    let startDate = new Date(),
      endDate = new Date();

    let arrStart = getDateOnly(this.eventControls.eSelectedDate.value).split(
      '-'
    );
    let arrEnd = getDateOnly(this.eventControls.eSelectedDate.value).split('-');

    startDate.setDate(parseInt(arrStart[2]));
    startDate.setMonth(parseInt(arrStart[1]) - 1);
    startDate.setFullYear(parseInt(arrStart[0]));

    endDate.setDate(parseInt(arrEnd[2]));
    endDate.setMonth(parseInt(arrEnd[1]) - 1);
    endDate.setFullYear(parseInt(arrEnd[0]));

    let timeST = set24TimeFormat(st).split(':') ?? null,
      timeET = set24TimeFormat(et).split(':') ?? null;

    if (timeST && timeST.length) {
      startDate.setHours(parseInt(timeST[0]));
      startDate.setMinutes(parseInt(timeST[1]));
    }

    if (timeET && timeET.length) {
      endDate.setHours(parseInt(timeET[0]));
      endDate.setMinutes(parseInt(timeET[1]));
    }

    startDate.setSeconds(0);
    endDate.setSeconds(0);

    console.log('start date/time: ', startDate);

    if (endDate <= startDate) {
      console.log('Error: End time can not be greater than start time!');
      
      this.helper.Notify(
        this.translate.instant('errorGreaterThanStartTime'),
        this.translate.instant('error'),
        3000
      );
      this.loaderService.actionLoader = false;
      return;
    }

    //if (endDate <= new Date()) {
    // this.helper.Notify(
    //   'Error ! End time can not be less than now!',
    //   'ALERT',
    //   3000
    // );
    // return;
    //}

    let now = new Date();
    now.setHours(now.getHours() + 1);

    // if (endDate < now && this.isSessionActive) {
    //   this.helper.Notify(
    //     'Error ! Time extension must be at least one hour for active sessions!',
    //     'ALERT',
    //     3000
    //   );
    //   return;
    // }
    // console.log('local time: ', new Date());

    //if (new Date() >= startDate && !this.isSessionActive) {
    //this.helper.Notify('Error ! Start time is incorrect!', 'ALERT', 3000);
    //return;
    //startDate = new Date(startDate.getTime() + minutes*60000);
    //}

    let location: LocationWithSimulatorSelection;
    let selectedSim: SimulatorSelectList;
    
    if (this.locations && this.locations.length) {
      this.locations.forEach((loc) => {
        loc.simulators?.every((sim) => {
          if (sim.id === this.eventControls.simulatorId.value) {
            selectedSim = sim;
            location = loc;
            return false;
          }
          return true;
        });
      });
    }

    let returnedEvent = new EventResponse({
      ...this.eventForm.getRawValue(),
      id: this.event.id === 0 ? --this.eventService.idGen : this.event.id,
      endDateTime: endDate,
      startDateTime: startDate,
      simulatorIdentifier: selectedSim.simulatorIdentifier,
      simulatorName: selectedSim.name,
      locationName: location.name,
    });

    // if (st !== this.startTime || et !== this.endTime) {
    //   let hasConflict: string = this.helper.hasConflictedEvents(
    //     returnedEvent,
    //     this.data.selectedDateEvents
    //   );

    //   if (hasConflict && hasConflict.length) {
    //     this.helper.Notify(
    //       'Error ! Time conflict with event: ' + hasConflict,
    //       'ALERT',
    //       3000
    //     );
    //     return;
    //   }
    // }

    let hasConflict: string =
      this.eventService.hasConflictedEvents(returnedEvent);

    if (hasConflict && hasConflict.length) {
      this.helper.Notify(
        'Error ! Time conflict with event: ' + hasConflict,
        this.translate.instant('error'),
        3000
      );
      this.loaderService.actionLoader = false;
      return;
    }

    this.restApiService.getAllClients().subscribe((clients) => {
      this.socketService.connections = clients.filter(
        (p) => p.type == ClientType.SimulatorTerminal
      );
      if (
        !this.socketService.connections.find(
          (p) => p.simId == selectedSim.simulatorIdentifier
        )
      ) {
        // this.helper.Notify('Error ! Simulator is offline!', 'ALERT', 3000);
        //return;
      }

      // this.helper.Notify(
      //   `Session was created/updated for: ${returnedEvent.name} from ${selectedSim.name}`,
      //   'INFO',
      //   3000
      // );

      // this.eventResult.mode = this.setEventModalMode();
      // this.eventResult.event = returnedEvent;
      // this.dialogRef.close(this.eventResult);

      if (!this.simulatorSelectedObjec.location) {
        let currentLoc = this.locations.find(
          (f) => f.id === +this.eventControls.simulatorId.value
        );
        this.simulatorSelectedObjec.location = currentLoc;
        this.simulatorSelectedObjec.simulator = currentLoc.simulators.find(
          (f) => f.id === this.data.simulatorId
        );
      }

      returnedEvent.location = this.simulatorSelectedObjec?.location;

      this.loaderService.actionLoader = false;

      this.dialogRef.close(
        new EventModalResult({
          fromSimulator: this.simulatorSelectedObjec?.simulator,
          fromLocation: this.simulatorSelectedObjec?.location,
          event: returnedEvent,
          mode: this.eventMode,
        })
      );
    });

    //this.startSession(returnedEvent)
  }

  setEventModalMode(): EventModalMode {
    if (!this.data || !this.data.id || this.data.id < 0) {
      this.eventMode = EventModalMode.create;
      return this.eventMode;
    }
    this.eventMode = EventModalMode.changed;
    return this.eventMode;
  }

  onChangeValue(simulatorId: number): void {
    if (this.event) this.event.simulatorId = simulatorId;
  }

  onClickSimSelector(
    simulator: SimulatorResponse,
    location: LocationResponse
  ): void {
    this.eventResult.fromLocation = location;
    this.eventResult.fromSimulator = simulator;
  }

  hasAnyLocations(): void {
    if (!this.data.locations || !this.data.locations.length) {
      this.helper.Notify(
        this.translate.instant('simulatorNotAvailable'),
        this.translate.instant('error'),
        3000
      );
    }
  }

  removeEvent(): void {
    const dialogRef = this.dialog.open(AlertModalComponent, {
      width: '450px',
      backdropClass: 'background-blur',
      data: {
        option: new AlertModalOption({
          type: AlertModalType.Question,
          role: 'yes',
          buttonName: this.translate.instant('yes'),
          description: this.translate.instant('areYouSureToRemove'),
          title: `${this.event.name}`,
          color: ColorPalette.Warning,
        }),
      },
    });
    dialogRef.afterClosed().subscribe((response: string) => {
      if (response !== 'yes') return;

      this.dialogRef.close({
        mode: EventModalMode.delete,
        event: new EventResponse({
          id: this.event.id,
          name: this.event.name,
          startDateTime: getUTCDateFromOffset(this.event.startDateTime),
          endDateTime: getUTCDateFromOffset(this.event.endDateTime),
          simulatorIdentifier: this.event.simulatorIdentifier,
        }),
      });
    });
  }

  onChangeDate(event: any): void {
    let date = this.helper.getDateOnly(event);
    this.eventForm.controls['eSelectedDate'].setValue(date);
  }

  increaseTime(min: number): void {
    let sTime = this.eventControls.eStartTime.value,
      selectDate = this.eventControls.eSelectedDate.value as Date;

    if (sTime && sTime.length) {
      sTime = set24TimeFormat(sTime);
      selectDate.setMinutes(selectDate.getMinutes() + min);
      this.eventControls.eStartTime.setValue(getTimeFromMoment(selectDate));
      this.eventControls.eEndTime.setValue(getTimeFromMoment(selectDate));
    }
  }

  addDurationTime(min: number): void {
    let endTime = this.eventControls.eEndTime.value,
      selectedDate = new Date(this.eventControls.eSelectedDate.value as Date);

    if (endTime && endTime.length) {
      endTime = set24TimeFormat(endTime);
      selectedDate.setMinutes(getMinutesFromTimeFormat(endTime));
      selectedDate.setHours(getHoursFromTimeFormat(endTime));
    }

    selectedDate.setMinutes(selectedDate.getMinutes() + min);
    this.eventControls.eEndTime.setValue(getTimeFromMoment(selectedDate));
  }

  changeStartTime(event: any): void {}

  openedTimePicker(): void {
    setTimeout(() => {
      let buttonsLabel = document.querySelectorAll(
        'ngx-mat-timepicker-dialog .mat-mdc-dialog-actions button .mdc-button__label'
      );
      buttonsLabel?.forEach((label: HTMLElement) => {
        label.innerText = this.translate
          .instant(label.innerText.toLowerCase().trim())
          ?.toUpperCase();
      });
    }, 50);
  }

  onTimeSet(event: any, timeFor: 'start' | 'end'): void {
    this.eventControls.eEndTime.reset();

    if (timeFor === 'start') {
      this.eventControls.eStartTime.setValue(event);
    } else {
      this.eventControls.eEndTime.setValue(event);
    }

    this.resetSelectedDate();
  }

  filterCustomers(event: any): void {
    this.customers = this.helper.searchData(
      event.target.value,
      this.tmpCustomers,
      'fullName'
    );
  }

  onChangeCustomer(event: any): void {
    let word: string = event.target.value;
    if (!word || !word.length) this.eventControls.customerId.setValue(null);
  }

  onOpenCustomerModal(): void {
    const dialogRef = this.dialog.open(CustomersModalComponent, {
      width: '600px',
      backdropClass: 'background-blur',
      data: {},
    });
    dialogRef.afterClosed().subscribe((response: any) => {
      if (response && response.id > 0) {
        let newCustomer = new CustomerSelectList({
          fullName: `${response.firstName} ${response.lastName}`,
          id: response.id,
        });
        this.customers.push(newCustomer);
        this.eventControls.customerId.setValue(newCustomer.id);
        this.setCustomerId();
      }
    });
  }

  onClickCustomerOption(customer: CustomerSelectList): void {
    this.customerInput.nativeElement.value = customer.fullName;
    this.eventControls.customerId.setValue(customer.id);
  }

  setCustomerId(): void {
    setTimeout(() => {
      if (this.customers && this.customers.length) {
        if (
          this.eventControls.customerId.value &&
          this.eventControls.customerId.value > 0
        )
          this.customerInput.nativeElement.value = this.customers.find(
            (f) => f.id === this.eventControls.customerId.value
          ).fullName;
      }
    }, 100);
  }

  cancelSession() {
    let st: string = this.eventForm.value.eStartTime;
    let et: string = this.eventForm.value.eEndTime;
    let startDate = this.helper.formatDate2(
      this.eventForm.value.eSelectedDate + ' ' + st
    );
    let endDate = this.helper.formatDate2(
      this.eventForm.value.eSelectedDate + ' ' + et
    );
    let selectedSimId = this.eventForm.value.eSimId;
    let selectedSim: SimulatorResponse;
    this.data.locations.forEach((loc) => {
      loc.simulators.forEach((sim) => {
        if (sim.id == selectedSimId) selectedSim = sim;
      });
    });
    this.dialogRef.close({
      mode: EventModalMode.cancelSession,
      event: new EventResponse({
        id: this.event.id,
        name: this.event.name,
        startDateTime: startDate,
        endDateTime: endDate,
        simulatorIdentifier: selectedSim.simulatorIdentifier,
      }),
    });
  }
}
