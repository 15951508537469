import { Injectable } from '@angular/core';
import { EventService } from 'src/app/shared/services/event.service';
import { Assistance } from '../../models/assistance';
import { ExtendSession } from '../../models/extend-session';
import { Alert, AlertType } from '../../models/alert';
import { HelperService } from '../../services/Helper.service';
import { RestApiService } from '../../services/RestApiServices.service';
import { WebSocketService } from '../../services/WebSocket.service';
import { SimulatorResponse } from '../../models/simulators.model';
import { environment } from 'src/environments/environment';
import { SignalType } from '../../models/Enums';
import { TransactionType } from '../../models/transaction';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MiniLoaderService } from '../mini-loader/mini-loader.service';
import { getUTCDateFromOffset } from '../../time.functions';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  public extendMinutes$ = new BehaviorSubject<ExtendSession>(null as any);

  private _notificationCounts: number;
  public get notificationCounts(): number {
    return this._notificationCounts;
  }
  public set notificationCounts(v: number) {
    this._notificationCounts = v;
  }

  private _notifications: Alert[];
  public get getNotifications(): Alert[] {
    return this._notifications;
  }

  notificationGranted: string;

  constructor(
    private helper: HelperService,
    private eventService: EventService,
    private webSocketService: WebSocketService,
    private restApiService: RestApiService,
    private translate: TranslateService,
    public loaderService: MiniLoaderService
  ) {
    this._notificationCounts = 0;
    this.initialNotifications();
    this.notificationGranted = 'default';
  }

  public setNotifications(notif: Alert[]): void {
    this._notifications = notif;
    this.updateNotifications();
  }

  public setNewNotification(
    extendSession: ExtendSession,
    assistance: Assistance,
    simulator: SimulatorResponse,
    sendAlert = false
  ): void {
    var alert: Alert = new Alert({
      assistance: null,
      extendSession: null,
      type: null,
    });

    if (assistance) {
      for (let index = 0; index < this._notifications.length; index++) {
        if (
          this._notifications[index].assistance &&
          this._notifications[index].assistance.simId === assistance.simId
        ) {
          return;
        }
      }

      this.restApiService
        .getSimulatorByIdentifier(assistance.simId)
        .subscribe((simulator) => {
          if (!simulator) return;

          console.log('simulator details loaded');

          // for (let i = 0; i < locations.length; i++) {
          //   const loc = locations[i];
          //   for (let j = 0; j < loc.simulators.length; j++) {
          //     const s = loc.simulators[j];
          //     if (s.simulatorIdentifier == assistance.simId) {

          //     }
          //   }
          // }

          let body =
            this.translate.instant('assistanceNeededFor') + simulator.name;
          this.helper.Notify(body, this.translate.instant('alert'), 3000);
          // this.browserNotification('Bay Manager', body, '');

          alert.assistance = assistance;
          alert.extendSession = null;
          alert.type = AlertType.assistance;
          alert.alertBody = body;

          this.addNotifications(alert);
        });
    } else if (extendSession) {
      for (let index = 0; index < this._notifications.length; index++) {
        if (
          this._notifications[index].extendSession &&
          this._notifications[index].extendSession.eventId ===
            extendSession.eventId
        ) {
          this._notifications[index].extendSession.minutes =
            extendSession.minutes;

          this.updateNotifications();
          // this.filterNotification(notification.eventId);
          // break;
          return;
        }
      }

      if (sendAlert) {
        let body =
          this.translate.instant('sessionExtensionRequestFor') +
          extendSession.eventName;
        this.helper.Notify(body, this.translate.instant('alert'), 3000);
        // this.browserNotification('Bay Manager', body, '');
      }

      alert.assistance = null;
      alert.extendSession = extendSession;
      alert.type = AlertType.extendSession;

      this.addNotifications(alert);
    } else if (simulator) {
      for (let index = 0; index < this._notifications.length; index++) {
        if (
          this._notifications[index].simulator &&
          this._notifications[index].simulator.simulatorIdentifier ===
            simulator.simulatorIdentifier
        ) {
          return;
        }
      }

      alert.simulator = simulator;
      alert.type = AlertType.simulator;

      this.addNotifications(alert);

      let body = `${this.translate.instant('licenseFor')} : ${
        simulator.name
      } ${this.translate.instant('hasExpired')}.`;
      this.helper.Notify(body, this.translate.instant('alert'), 3000);
    }
    //this._notifications.unshift(alert);
  }

  /**
   * Filter notifications
   *
   * @param eventId
   */
  public filterNotification(eventId: number, simId, type: AlertType): void {
    for (let i = 0; i < this._notifications.length; i++) {
      const notif = this._notifications[i];
      if (
        type == this._notifications[i].type &&
        type == AlertType.extendSession
      ) {
        if (this._notifications[i].extendSession.eventId === eventId) {
          this._notifications.splice(i, 1);
          this.updateNotifications();
        }
      } else if (
        type == this._notifications[i].type &&
        type == AlertType.assistance
      ) {
        if (this._notifications[i].assistance.simId === simId) {
          this._notifications.splice(i, 1);
          this.updateNotifications();
        }
      }
      this._notificationCounts--;
    }
  }

  /**
   * Remove notifications from list when delete or time over event
   *
   * @param eventId
   */
  public removeNotification(simId: number, eventId: number): void {
    if (!this._notifications || !this._notifications.length) return;

    let notifLen = this._notifications.length;
    for (let index = 0; index < notifLen; index++) {
      if (
        this._notifications[index].simulator &&
        this._notifications[index].simulator.id === simId
      ) {
        let sim = this._notifications[index].simulator;
        if (typeof sim !== 'undefined' && typeof sim !== null) {
          let event = sim.events.find((f) => f.id === eventId);
          if (typeof event !== 'undefined' && typeof event !== null) {
            this._notifications = this._notifications.filter((e, i) => {
              return index !== i;
            });
            this.updateNotifications();
            return;
          }
        }
      }
    }
  }

  public requestAndShowPermission() {
    var isMobile = /iPhone|iPad|iPod|Android|AppleWebKit/i.test(
      navigator.userAgent
    );
    if (isMobile) return;
    var self = this;
    Notification.requestPermission(function (permission) {
      console.log(permission);
      //  if (permission === "granted") {
      self.notificationGranted = 'granted';
      // }
    });
  }

  public browserNotification(title: string, body: string, icon: string) {
    var notification = new Notification(title, { body, icon });
    notification.onclick = () => {
      notification.close();
      window.parent.focus();
    };
    setTimeout(() => {
      notification.close.bind(notification);
    }, 6000);
  }

  public async onApproved(extendSignal: ExtendSession): Promise<void> {
    try {
      this.loaderService.loader = true;
      let currentEvent = await lastValueFrom(
        this.eventService.getItemById(extendSignal.eventId, '/Event')
      );
      currentEvent.endDateTime = getUTCDateFromOffset(currentEvent.endDateTime);
      currentEvent.startDateTime = getUTCDateFromOffset(currentEvent.startDateTime);
      console.log("curent event on approved extension:",currentEvent)
      if (currentEvent && this.helper.isValidExtendMinute(currentEvent, extendSignal.minutes)) {
        extendSignal.approved = true;

        await this._sendExtendMinutesRequest(extendSignal);
      } else {
        console.log('Error:',currentEvent);
        
        this.helper.Notify(
          this.translate.instant('errorGreaterThanStartTime'),
          this.translate.instant('error'),
          3000
        );

        await this.onReject(extendSignal);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  public async onReject(extendSignal: ExtendSession): Promise<void> {
    try {
      this.loaderService.loader = true;
      extendSignal.approved = false;
      extendSignal.minutes = 0;

      await this._sendExtendMinutesRequest(extendSignal);
    } catch (error) {
      console.error(error);
    } finally {
      this.loaderService.loader = false;
    }
  }

  private async _sendExtendMinutesRequest(
    extendSignal: ExtendSession
  ): Promise<void> {
    try {
      const extendResult = await lastValueFrom(
        this.eventService.updateExtendedMinutes(extendSignal)
      );

      if (extendResult) {
        window.localStorage.removeItem(environment.bayManagerUser);

        if (extendSignal.approved) {
          this.helper.Notify(
            this.translate.instant('sessionTimeWasUpdated'),
            this.translate.instant('success'),
            3000
          );
        } else {
          this.helper.Notify(
            this.translate.instant('sessionTimeRequestWasRejected'),
            this.translate.instant('success'),
            3000
          );
        }

        this.webSocketService.sendSessionExtensionSignal(
          extendSignal,
          extendSignal.simId
        );

        setTimeout(() => {
          this.webSocketService.sendSignal(
            null,
            SignalType.Transaction,
            new Date().getTime(),
            new Date().getTime(),
            '',
            {
              transaction: JSON.stringify({
                eventId: extendSignal.eventId,
                transactionType: TransactionType.EventExtend,
                simulatorIdentifier: extendSignal.simId,
                extendedMinutes: extendSignal.minutes,
                tabId: sessionStorage.getItem('BayManager-TabId'),
              }),
            }
          );
        }, 500);

        if (extendSignal.approved) this.extendMinutes$.next(extendSignal);
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.filterNotification(
        extendSignal.eventId,
        null,
        AlertType.extendSession
      );
    }
  }

  public removeExtendSessionsByEventId(eventId: number): void {
    let updateNotifs = this.getNotifications.filter(
      (f) => f.extendSession?.eventId !== eventId
    );
    this.setNotifications(updateNotifs);
  }

  public dismissAssistance(assistance: Assistance) {
    if (assistance.simId && assistance.simId.length)
      window.localStorage.removeItem('_fs_alert_' + assistance.simId);

    this.filterNotification(null, assistance.simId, AlertType.assistance);
  }

  public initialNotifications(): void {
    let storedNotifs = window.localStorage.getItem('Bay-Manager-Notifs');

    if (storedNotifs && storedNotifs.length) {
      this._notifications = JSON.parse(storedNotifs);
      this._notificationCounts = this._notifications.length;
    } else {
      this._notifications = new Array<Alert>();
    }
  }

  public addNotifications(notif: Alert): void {
    let storedNotifs = window.localStorage.getItem('Bay-Manager-Notifs'),
      notifications: Alert[];

    if (storedNotifs && storedNotifs.length) {
      notifications = JSON.parse(storedNotifs);
    } else {
      notifications = new Array<Alert>();
    }

    notifications.unshift(notif);
    this._notifications.unshift(notif);

    window.localStorage.setItem(
      'Bay-Manager-Notifs',
      JSON.stringify(notifications)
    );

    this._notificationCounts++;
  }

  public updateNotifications(): void {
    window.localStorage.setItem(
      'Bay-Manager-Notifs',
      JSON.stringify(this._notifications)
    );
    this._notificationCounts = this._notifications.length;
  }

  public resetNotifications(): void {
    window.localStorage.removeItem('Bay-Manager-Notifs');
    this._notifications = new Array<Alert>();
    this._notificationCounts = 0;
  }
}
